<template>
  <div class="auxin" style="color: white; display: block;" @keydown="returnFn($event)">
    <div class="back" tabindex="1" @click="back()" ref="back" @keydown="backKeyDown($event)">
        Exit
      </div>
      <div style="display: -webkit-flex;-webkit-justify-content: center;
      margin: 100px;">
        <!-- <div>open aux</div> -->
        <div>
          <!-- <button ref="checkbox" @click="sendCommand($event)" @keydown="radioKeyDown($event)">{{!state?'open aux':'close aux'}}</button> -->
          <my-button ref="checkbox" :isLoading="connecting" @click="sendCommand($event)" @keydown="radioKeyDown($event)">{{!state?'open aux':'close aux'}}</my-button>
          <!-- <input ref="checkbox" style="margin-left: 20px;" type="checkbox" @click="sendCommand($event)" @keydown="radioKeyDown($event)"> -->
        </div>
      </div>
      <div id="blueTip" style="text-align: center;font-size:25px">{{tip}}</div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
export default {
  mounted () {
    this.$plugin.event.addEventListener('uartResolve', this.resolve)
    this.$refs.checkbox.focus()
  },
  data () {
    return {
      tip: '',
      state: false,
      connecting: false
    }
  },
  created () {
    this.$plugin.send({
      data: [0x55, 0xAA, 0x02, 0x01, 0x03, 0x16]
    })
    this.state = this.$plugin.aux === 1
    if (this.$plugin.aux === 1) {
      this.tip = 'aux is connect success'
    } else {
      this.tip = 'aux is connect failed'
    }
  },
  methods: {
    back () {
      this.$router.replace('/')
    },
    sendCommand (e) {
      var ischecked = !this.state
      var array = [0x55, 0xAA, 0x02, 0x01, 0x00, 0x16]
      if (ischecked) {
        array[4] = 0x03
      }
      this.connecting = true
      this.$plugin.send({
        data: array
      })
      // this.$refs.back.focus()
    },
    radioKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        // case 'up':
        //   this.$refs.back.focus()
        //   break
        case 'ok':
          target.click()
          break
      }
    },
    backKeyDown (e) {
      const key = keyMap[e.keyCode]
      const target = e.target
      switch (key) {
        case 'down':
          this.$refs.checkbox.focus()
          break
        case 'ok':
          target.click()
          break
      }
    },
    resolve (data) {
      if (data.type === 'aux') {
        this.connecting = false
        this.state = data.state === 1
        if (data.state === 1) {
          this.tip = 'aux is connect success'
        } else {
          this.tip = 'aux is connect failed'
        }
      }
    },
    returnFn (e) {
      const key = keyMap[e.keyCode]
      if (key === 'return') {
        this.$router.replace('/')
      }
    }
  },
  beforeDestroy () {
    this.$plugin.event.removeEventListener('uartResolve', this.resolve)
  }
}
</script>
<style lang="scss" scoped>
.auxin{
    background-color: black;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 11;
    .back{
      display: block;
      width: 80px;
      font-size: 30px;
      text-align: center;
      color: #fff;
      border-radius: 20px;
      &:focus{
        background: gray;
      }
    }
    input{
      &:focus{
        -webkit-transform: scale(3);
      }
    }
    button{
      font-size: 35px;
      padding: 5px;
      border-radius: 5px;
      &:focus{
        outline: yellow solid 3px;
      }
    }
}
</style>
